<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <!-- This is called slot template  -->
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="New"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb*2"
              @click="openNew"
              :disabled="staffPosition != 'Technician'"
            />

            <!-- <Button
              label="New"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb*2"
              @click="openNew"
            /> -->
          </template>

          <!-- <template v-slot:right>
            <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="p-mr-2 p-mb-2 p-d-inline-block"
            />
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help p-mb-2"
              @click="exportCSV($event)"
            />
          </template> -->
        </Toolbar>

        <DataTable
          ref="dt"
          :value="receipts"
          v-model:expandedRows="expandedRowsReceipt"
          dataKey="id"
          :filters="filters"
          filterDisplay="menu"
          :paginator="true"
          :rows="5"
          :lazy="true"
          @page="onPage($event)"
          @sort="onSort($event)"
          :totalRecords="totalReceipts"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} receipts"
          @row-expand="onRowExpand"
          @row-collapse="onRowCollapse"
          :loading="loadingGetReceipts"
          class="p-datatable-responsive-demo"
          responsiveLayout="scroll"
          stripedRows
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Manage Receipts</h5>
              <div class="table-header-container">
                <!-- <Button
                  icon="pi pi-plus"
                  label="Expand All"
                  @click="expandAll"
                  class="p-mr-2"
                />
                <Button
                  icon="pi pi-minus"
                  label="Collapse All"
                  @click="collapseAll"
                />&nbsp;&nbsp; -->
                <span class="p-input-icon-left">
                  <!-- <i class="pi pi-search" />
                  <InputText
                    style="width: 100%"
                    v-model="filters['global'].value"
                    placeholder="Search..."
                  /> -->

                  <!-- <div
                    class="spinner-border text-primary"
                    v-if="loadingFilter"
                  ></div> -->
                  <div
                    class="spinner-border text-primary"
                    v-if="isSuperAdmin && loadingOutlets"
                  ></div>
                  <Dropdown
                    id="searchFilterbyOutlet"
                    v-model="searchFilterbyOutlet"
                    :options="searchFilterbyOutletOptions"
                    optionLabel="Choose outlet"
                    placeholder="Select a outlet"
                    style="width: 175px"
                    v-if="isSuperAdmin && !loadingOutlets"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>
                          {{ slotProps.value.name }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>
                          {{ slotProps.option.name }}
                        </div>
                      </div>
                    </template>
                  </Dropdown>
                  <Dropdown
                    id="searchFilterbyCategory"
                    v-model="searchFilterbyCategory"
                    :options="searchFilterbyCategoryOptions"
                    optionLabel="Choose category"
                    placeholder="Select a category"
                    style="width: 150px"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>
                          {{ searchFilterbyCategoryName(slotProps.value) }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>
                          {{ searchFilterbyCategoryName(slotProps.option) }}
                        </div>
                      </div>
                    </template>
                  </Dropdown>
                  <Dropdown
                    id="searchBy"
                    v-model="searchBy"
                    :options="searchByOptions"
                    optionLabel="Choose filter"
                    placeholder="Select a filter"
                    style="width: 150px"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>
                          {{ slotProps.value.name }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>
                          {{ slotProps.option.name }}
                        </div>
                      </div>
                    </template>
                  </Dropdown>

                  <InputMask
                    id="customerContact"
                    placeholder="Search by Contact"
                    v-model.trim="searchByString"
                    mask="999-9999 999?9"
                    v-if="searchBy.id == 1"
                  />
                  <InputText
                    v-model.trim="searchByString"
                    placeholder="Search by Receipt Code"
                    v-if="searchBy.id == 2"
                  />
                </span>

                <Button
                  label="Search"
                  class="p-button"
                  @click="loadLazyData()"
                />
              </div>
            </div>
          </template>

          <Column :expander="true" headerStyle="width: 3rem" />

          <Column field="receiptCode" header="Receipt Code">
            <template #body="slotProps">
              <span class="p-column-title">Receipt Code</span>
              {{ slotProps.data.receiptCode }}
            </template>
          </Column>
          <Column field="receiverName" header="Staff Name">
            <template #body="slotProps">
              <span class="p-column-title">Staff Name</span>
              {{ slotProps.data.receiverName }}
            </template>
          </Column>
          <Column field="customerName" header="Name">
            <template #body="slotProps">
              <span class="p-column-title">Customer Name</span>
              {{ slotProps.data.customerName }}
            </template>
          </Column>
          <Column field="initialDate.toString()" header="Receipt Date">
            <template #body="slotProps">
              <span class="p-column-title">Receipt Date</span>
              {{ initialDate(slotProps.data.initialDate) }}
            </template>
          </Column>

          <Column header="Expected Finish Date">
            <template #body="slotProps">
              <span class="p-column-title">Expected Finish Date</span>
              {{
                expectedFinishDateTime(
                  slotProps.data.expectedFinishDate,
                  slotProps.data.expectedFinishTime,
                )
              }}
            </template>
          </Column>
          <Column field="receiptServiceStatus" header="Status">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              <span
                :class="{
                  inProgress:
                    slotProps.data.receiptServiceStatus !== 'Completed',
                  done: slotProps.data.receiptServiceStatus === 'Completed',
                }"
                >{{ slotProps.data.receiptServiceStatus }}</span
              >
            </template>
          </Column>
          <Column field="customerContact" header="Contact">
            <template #body="slotProps">
              <span class="p-column-title">Contact</span>
              {{ slotProps.data.customerContact }}
            </template>
          </Column>
          <Column field="totalPrice" header="Total Cost">
            <template #body="slotProps">
              <span class="p-column-title">Total Cost</span>
              {{
                formatCurrency(slotProps.data.totalPrice)
                  ? formatCurrency(slotProps.data.totalPrice)
                  : 'Free'
              }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <!-- <Button
                v-if="alternative"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="editReceipt(slotProps.data)"
              />
              <Button
                icon="pi pi-file-o"
                class="p-button-rounded p-button-success p-mr-2"
                @click="addInfo(slotProps.data)"
              /> -->
              <!-- <Button
                icon="pi pi-print"
                class="p-button-rounded p-button-success p-mr-2"
                @click="exportReceipt($event)"
              /> -->
              <img
                id="img"
                src="assets/layout/images/receipt_repairing_imika.jpg"
                hidden
              />
              <img
                v-for="device in slotProps.data.deviceDetails"
                :id="'img' + device.id"
                :src="device.image"
                v-bind:key="device.id"
                :onload="imgLoadedConfirmed()"
                hidden
              />
              <div class="spinner-border text-primary" v-if="!imgLoaded"></div>
              <Button
                :disabled="loadingPrint"
                v-if="imgLoaded"
                icon="pi pi-print"
                class="p-button-rounded p-button-success p-mr-2"
                @click="exportReceipt(slotProps.data)"
              />
            </template>
          </Column>

          <template #expansion="slotProps2">
            <div class="orders-subtable">
              <h5>Devices of {{ slotProps2.data.customerName }}</h5>

              <DataTable
                :value="slotProps2.data.deviceDetails"
                :rowClass="rowClass"
                style="align: center; text-align: center"
              >
                <Column field="image" header="Picture">
                  <template #body="slotProps2"
                    ><br />
                    <img :src="slotProps2.data.image" class="receipt-image" />

                    <!-- <img
                      v-else
                      src="assets/layout/images/devices/laptop_1.jpg"
                      alt="assets/layout/images/devices/laptop_1.jpg"
                      class="receipt-image"
                    /> -->
                  </template>
                </Column>
                <Column field="name" header="Name">
                  <template #body="slotProps2"
                    ><br />
                    {{
                      slotProps2.data.brand.name +
                      ' ' +
                      slotProps2.data.model +
                      ' (' +
                      slotProps2.data.color.name +
                      ')'
                    }}
                  </template>
                </Column>
                <Column field="defects" header="Defection">
                  <template #body="slotProps2">
                    <div
                      v-for="(defect, index) in slotProps2.data.defects"
                      v-bind:key="defect.id"
                    >
                      <br />
                      {{ index + 1 }}) {{ defect.name }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="technicalServiceDetails"
                  header="Service|SparePart"
                >
                  <template #body="slotProps2">
                    <br />
                    <u>Services</u>
                    <div
                      v-for="(service, index) in slotProps2.data
                        .technicalServiceDetails"
                      :key="service"
                    >
                      {{
                        index +
                        1 +
                        '. ' +
                        service.name +
                        ' (' +
                        service.category +
                        ') '
                      }}
                    </div>
                    <br />
                    <u>Spare-Parts</u>
                    <div
                      v-for="(sparePart, index) in slotProps2.data
                        .sparePartDetails"
                      :key="sparePart"
                    >
                      {{
                        index +
                        1 +
                        '. ' +
                        sparePart.name +
                        ' (' +
                        sparePart.category +
                        ') '
                      }}
                    </div>
                    <br />
                  </template>
                </Column>
                <Column field="description" header="Description">
                  <template #body="slotProps2"
                    ><br />
                    {{ slotProps2.data.description }}
                  </template>
                </Column>
                <Column field="leftWiths" header="Left in Shop">
                  <template #body="slotProps2">
                    <br />
                    <div
                      v-for="(left, index) in slotProps2.data.leftWiths"
                      v-bind:key="left"
                    >
                      {{ left
                      }}{{
                        slotProps2.data.leftWiths.length > 1 &&
                        slotProps2.data.leftWiths.length != index + 1
                          ? ','
                          : null
                      }}
                    </div>
                  </template>
                </Column>

                <Column field="action">
                  <template #body="slotProps2">
                    <Button
                      icon="pi pi-pencil"
                      class="p-button-rounded p-button-success p-mr-2"
                      @click="updateDeviceStatus(slotProps2.data)"
                    />
                  </template>
                </Column>
                <template #empty> No records found. </template>
              </DataTable>
            </div>
          </template>
          <template #empty> No records found. </template>
          <template #loading> Loading customers data. Please wait. </template>
        </DataTable>
        <Dialog
          v-model:visible="receiptAddInfoDialog"
          :style="
            screenHeight > screenWidth
              ? 'width: 95%'
              : browserHeight > browserWidth
              ? 'width: 95%'
              : 'width: 40%'
          "
          header="Additional Info"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="note">Note</label>
            <Textarea
              id="note"
              v-model.trim="receipt.note"
              required="true"
              :class="{ 'p-invalid': submitted && !receipt.note }"
            />
            <small class="p-invalid" v-if="submitted && !receipt.note"
              >Note is required.</small
            >
          </div>
          <div class="p-field">
            <label for="attachments">Attachments</label>
            <DataTable id="attachments" :value="receipt.attachment">
              <Column field="number" header="NO."></Column>
              <Column field="name" header="Name"></Column>
              <Column field="category" header="Category"></Column>
              <Column field="action" header="Action"></Column>
              <template #empty>
                <p>No data available</p>
              </template>
            </DataTable>
          </div>
          <div class="p-field">
            <FileUpload
              name="demo[]"
              url="./upload.php"
              @upload="onUpload"
              :multiple="true"
              accept="image/*"
              :maxFileSize="1000000"
            >
              <template #empty>
                <p>Drag and drop files to here to upload.</p>
              </template></FileUpload
            >
          </div></Dialog
        ><Dialog
          v-model:visible="updateDeviceStatusDialog"
          :style="
            screenHeight > screenWidth
              ? 'width: 95%'
              : browserHeight > browserWidth
              ? 'width: 95%'
              : 'width: 27%'
          "
          header="Device Status"
          :modal="true"
          class="p-fluid"
        >
          <img
            v-if="device.completionImage != null"
            :src="device.completionImage"
            class="receipt-image"
          />
          <div
            class="p-field"
            v-for="serviceStatusTech in serviceStatusTechFilters"
            :key="serviceStatusTech"
          >
            <h6>
              {{ serviceStatusTech.name }} ({{ serviceStatusTech.category }})
            </h6>

            <div
              v-for="index in Math.trunc(
                serviceStatusTech.statusList.length / 6 + 1,
              )"
              :key="index"
            >
              <Timeline
                :value="events(index, serviceStatusTech)"
                layout="horizontal"
                align="bottom"
                dataKey="id"
                class="customized-timeline"
                style="
                  text-align: center;
                  word-wrap: break-word;
                  width: 100%;
                  justify-content: space-between;
                  word-spacing: 100px;
                "
                :index="index"
              >
                <template #marker="slotProps">
                  <span
                    class="custom-marker p-shadow-2"
                    style="border-radius: 100px"
                    ><i
                      v-if="
                        serviceStatusTech.statusStage <
                        slotProps.index + (index - 1) * 5
                      "
                      class="pi pi-circle-off"
                      style="color: red"
                    ></i
                    ><i
                      v-else-if="
                        serviceStatusTech.statusStage ==
                        slotProps.index + (index - 1) * 5
                      "
                      class="pi pi-circle-off"
                      style="color: green"
                    ></i>
                    <i
                      v-else-if="
                        serviceStatusTech.statusStage >
                        slotProps.index + (index - 1) * 5
                      "
                      :class="{
                        'pi pi-check-circle':
                          serviceStatusTech.isSucceed[
                            slotProps.index + (index - 1) * 5
                          ] == true,
                        'pi pi-times-circle':
                          serviceStatusTech.isSucceed[
                            slotProps.index + (index - 1) * 5
                          ] == false,
                      }"
                      :style="
                        serviceStatusTech.isSucceed[
                          slotProps.index + (index - 1) * 5
                        ]
                          ? 'color: green'
                          : 'color: red'
                      "
                    ></i
                  ></span>
                </template>
                <template #content="slotProps">
                  {{ slotProps.item }}
                </template>
              </Timeline>
            </div>
            <!-- <div
              v-if="
                (serviceStatusTech.isAdmin[serviceStatusTech.statusStage] ==
                  false &&
                  staffPosition != 'Administrator') ||
                (serviceStatusTech.isAdmin[serviceStatusTech.statusStage] ==
                  true &&
                  staffPosition == 'Administrator')
              "
            >
              <Button
                label="Tick"
                icon="pi pi-check-circle"
                class="p-button-success p-mr-2 p-mb*2"
                @click="updateDeviceStatusTickCross(serviceStatusTech)"
              />
            </div>
            <div v-else>
              <Button
                label="Tick"
                icon="pi pi-check-circle"
                class="p-button-success p-mr-2 p-mb*2"
                @click="updateDeviceStatusTickCross(serviceStatusTech)"
                disabled
              />
            </div> -->
            <div
              class="row"
              v-if="
                serviceStatusTech.statusStage !=
                serviceStatusTech.statusList.length
              "
            >
              <div class="col-sm m-2">
                <Button
                  label="Cross"
                  icon="pi pi-times-circle"
                  class="p-button-danger p-mr-2 p-mb*2"
                  @click="
                    notAllDoneYet &&
                    serviceStatusTech.statusStage + 1 >=
                      serviceStatusTech.statusList.length - 1
                      ? triggerWarningDialog()
                      : serviceStatusTech.statusStage + 1 ==
                        serviceStatusTech.statusList.length
                      ? triggerCompletionDialog(serviceStatusTech, 0)
                      : updateDeviceStatusTickCross(serviceStatusTech, 0)
                  "
                  :disabled="staffPosition != 'Branch Administrator'"
                />
                <!-- "
                    (serviceStatusTech.isAdmin[serviceStatusTech.statusStage] ==
                      true &&
                      staffPosition != 'Warehouse') ||
                      (serviceStatusTech.isAdmin[
                        serviceStatusTech.statusStage
                      ] == false &&
                        staffPosition == 'Warehouse') ||
                      isSuperAdmin
                  " -->
              </div>

              <div class="col-sm m-2">
                <Button
                  label="Tick"
                  icon="pi pi-check-circle"
                  class="p-button-success p-mr-2 p-mb*2"
                  @click="
                    notAllDoneYet &&
                    serviceStatusTech.statusStage + 1 >=
                      serviceStatusTech.statusList.length - 1
                      ? triggerWarningDialog()
                      : serviceStatusTech.statusStage + 1 ==
                        serviceStatusTech.statusList.length
                      ? triggerCompletionDialog(serviceStatusTech, 1)
                      : updateDeviceStatusTickCross(serviceStatusTech, 1)
                  "
                  :disabled="
                    (serviceStatusTech.isAdmin[serviceStatusTech.statusStage] ==
                      true &&
                      staffPosition != 'Warehouse') ||
                    (serviceStatusTech.isAdmin[serviceStatusTech.statusStage] ==
                      false &&
                      staffPosition == 'Warehouse') ||
                    isSuperAdmin
                  "
                />
              </div>
            </div>

            <Button
              label="Completed"
              class="p-button-success p-mr-2 p-mb*2"
              disabled
              v-else
            />
            <!-- || (notAllDoneYet && serviceStatusTech.statusStage==) -->
          </div>
        </Dialog>
        <Dialog
          v-model:visible="receiptDialog"
          :style="
            screenHeight > screenWidth
              ? 'width: 95%'
              : browserHeight > browserWidth
              ? 'width: 95%'
              : 'width: 40%'
          "
          header="Service Details"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="name">Customer Name</label>
            <InputText
              id="name"
              v-model.trim="receipt.customerName"
              required="true"
              :class="{ 'p-invalid': submitted && !receipt.customerName }"
            />

            <small class="p-invalid" v-if="submitted && !receipt.customerName"
              >Customer name is required.</small
            >
          </div>
          <div class="p-field">
            <label for="customerContact">Contact Number</label>

            <InputMask
              id="customerContact"
              v-model.trim="receipt.customerContact"
              :class="{ 'p-invalid': submitted && !receipt.customerContact }"
              mask="999-9999 999?9"
            />

            <!-- mask="(999) 999-9999?9" -->
            <small
              class="p-invalid"
              v-if="submitted && !receipt.customerContact"
              >Contact number is required.</small
            >
          </div>
          <div class="p-field">
            <label for="receiverName">Receiver Name</label>
            <!-- No Data -->
            <InputText
              id="receiverName"
              v-model.trim="receipt.receiverName"
              required="true"
              :class="{ 'p-invalid': submitted && !receipt.receiverName }"
              disabled
            />
            <small class="p-invalid" v-if="submitted && !receipt.receiverName"
              >Receiver name is required.</small
            >
          </div>
          <div class="p-field">
            <label for="initialDate">Receive Date</label>
            <Calendar
              id="receive_datetime"
              v-model="receipt.initialDate"
              :class="{ 'p-invalid': submitted && !receipt.initialDate }"
              disabled
            />
            <small class="p-invalid" v-if="submitted && !receipt.initialDate"
              >Receive Datetime is required.</small
            >
          </div>

          <div class="p-field">
            <label for="deviceDetails">Device</label>
            <!-- change 1 -->
            <DataTable
              :value="receipt.deviceDetails"
              dataKey="id"
              :filters="filters1"
              editMode="cell"
              class="editable-cells-table"
              responsiveLayout="scroll"
              :loading="loadingGetDevices2"
            >
              <!-- <Column
                field="id"
                header="ID"
                headerStyle="text-align:center"
                style="width: 10%"
              >
              </Column> -->
              <Column
                field="image"
                header="Image"
                headerStyle="text-align:center"
                style="width: 30%"
              >
                <template #body="slotProps">
                  <img
                    v-if="slotProps.data.image != null"
                    :src="slotProps.data.image"
                    :alt="slotProps.data.image"
                    class="device-image"
                  />
                  <img
                    v-else
                    src="assets/layout/images/device/laptop_1.jpg"
                    alt="assets/layout/images/device/laptop_1.jpg"
                    class="receipt-image"
                  />
                </template>
              </Column>
              <Column
                field="model"
                header="Model"
                headerStyle="text-align:center"
                style="width: 30%"
              >
                <template #body="slotProps">
                  ({{ slotProps.data.color.name }})
                  {{ slotProps.data.brand.name }} {{ slotProps.data.model }}
                </template>
              </Column>
              <Column
                header="Action"
                style="width: 30%; min-width: 8rem; text-align: center"
                field="action"
              >
                <template #body="slotProps">
                  <!-- <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-success p-mr-2"
                    @click="editDevice(slotProps.data)"
                  /> -->
                  <Button
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-warning"
                    @click="confirmDeleteDevice(slotProps.data)"
                  />
                </template>
              </Column>

              <template #empty> No records found. </template>
            </DataTable>

            <Button
              label="Add Device"
              icon="pi pi-external-link"
              class="p-button-raised"
              @click="openAddDevice"
            />
            <Dialog
              header="Device Details"
              v-model:visible="deviceDialog"
              :style="
                screenHeight > screenWidth
                  ? 'width: 95%'
                  : browserHeight > browserWidth
                  ? 'width: 95%'
                  : 'width: 40%'
              "
              class="p-fluid"
              :modal="true"
            >
              <div class="p-field">
                <label for="deviceCategory">Device Category</label>
                <Dropdown
                  id="deviceCategory"
                  v-model="deviceCategory"
                  :options="deviceCategories"
                  optionLabel="deviceCategory"
                  placeholder="Select a Device Category"
                >
                  <template #value="slotProps">
                    <div
                      class="country-item country-item-value"
                      v-if="slotProps.value.name"
                    >
                      <div>{{ slotProps.value.name }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="country-item">
                      <div>{{ slotProps.option.name }}</div>
                    </div>
                  </template>
                </Dropdown>
              </div>

              <div
                class="spinner-border text-primary"
                v-if="
                  loadingGetServices ||
                  loadingGetSpareParts ||
                  loadingGetBrandOptions ||
                  loadingGetDefectOptions ||
                  loadingGetDeviceTypes
                "
              ></div>
              <div v-else-if="deviceCategory.name != null">
                <div class="p-field">
                  <label for="brand">Brand</label>
                  <Dropdown
                    id="brand"
                    v-model="device.brand"
                    :options="brandOptions"
                    optionLabel="name"
                    placeholder="Select a Brand"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value.name"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>

                  <small
                    class="p-invalid"
                    v-if="submitted && !device.brand.name"
                    >Device Brand is required.</small
                  >
                </div>
                <div class="p-field">
                  <label for="color">Color</label>
                  <Dropdown
                    id="color"
                    v-model="device.color"
                    :options="colorOptions"
                    optionLabel="name"
                    placeholder="Select a Color"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value.name"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                  <small
                    class="p-invalid"
                    v-if="submitted && !device.color.name"
                    >Device color is required.</small
                  >
                </div>

                <div class="p-field">
                  <label for="model">Device Model / Serial Number</label>
                  <InputText
                    id="model"
                    v-model.trim="device.model"
                    required="true"
                    :class="{ 'p-invalid': submitted && !device.model }"
                  />

                  <small class="p-invalid" v-if="submitted && !device.model"
                    >Device model / serial number is required.</small
                  >
                </div>

                <div class="p-field">
                  <label>Type</label>
                  <div class="p-formgrid p-grid">
                    <div
                      class="p-field-radiobutton p-col-6"
                      v-for="(deviceType, index) in deviceTypes"
                      v-bind:key="deviceType.id"
                    >
                      <RadioButton
                        :id="'category' + index.toString()"
                        name="category"
                        :value="deviceType.name"
                        v-model="device.type"
                      />
                      <label :for="'category' + index.toString()">{{
                        deviceType.name
                      }}</label>
                    </div>
                    <!-- <div class="p-field-radiobutton p-col-6">
                    <RadioButton
                      id="category1"
                      name="category"
                      value="Laptop"
                      v-model="device.type"
                    />
                    <label for="category1">Laptop</label>
                  </div>
                  <div class="p-field-radiobutton p-col-6">
                    <RadioButton
                      id="category2"
                      name="category"
                      value="Monitor"
                      v-model="device.type"
                    />
                    <label for="category2">Monitor</label>
                  </div>
                  <div class="p-field-radiobutton p-col-6">
                    <RadioButton
                      id="category3"
                      name="category"
                      value="CPU"
                      v-model="device.type"
                    />
                    <label for="category3">CPU</label>
                  </div> -->
                  </div>
                  <small class="p-invalid" v-if="submitted && !device.type"
                    >Device type is required.</small
                  >
                </div>

                <div class="p-field">
                  <label>Defects</label>
                  <span class="p-fluid">
                    <MultiSelect
                      v-model="device.defects"
                      :options="defectOptions"
                      optionLabel="name"
                      :filter="true"
                      class="multiselect-custom"
                    >
                      <template #value="slotProps">
                        <div
                          class="country-item country-item-value"
                          v-for="option of slotProps.value"
                          :key="option.id"
                        >
                          <!-- <span
                          :class="'flag flag-' + option.code.toLowerCase()"
                        /> -->
                          <div>{{ option.name }}</div>
                        </div>
                        <template
                          v-if="
                            !slotProps.value || slotProps.value.length === 0
                          "
                        >
                          Select Countries
                        </template>
                      </template>
                      <template #option="slotProps">
                        <div class="country-item">
                          <!-- <span
                          :class="
                            'flag flag-' + slotProps.option.code.toLowerCase()
                          "
                        /> -->
                          <div>{{ slotProps.option.name }}</div>
                        </div>
                      </template>
                    </MultiSelect>
                  </span>

                  <small
                    class="p-invalid"
                    v-if="submitted && device.defects.length == 0"
                    >Device defects is required.</small
                  >
                </div>

                <div class="p-field">
                  <label>Services</label>
                  <div>
                    <DataTable
                      :value="device.technicalServiceDetails"
                      dataKey="id"
                      :filters="filters3"
                      editMode="cell"
                      class="editable-cells-table"
                      responsiveLayout="scroll"
                    >
                      <Column
                        field="name"
                        header="Name"
                        headerStyle="text-align:center"
                        style="width: 20%"
                      >
                      </Column>
                      <Column
                        field="category"
                        header="Category"
                        headerStyle="text-align:center"
                        style="width: 20%"
                      >
                        <template #editor="slotProps">
                          <Dropdown
                            v-model="slotProps.data['category']"
                            :options="serviceCategoryOptions"
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select a Category"
                          >
                            <template #option="slotProps">
                              <span
                                :class="
                                  'product-badge status-' +
                                  slotProps.option.name.toLowerCase()
                                "
                                >{{ slotProps.option.name }}</span
                              >
                            </template>
                          </Dropdown>
                        </template>
                        <template #body="slotProps">
                          {{ slotProps.data.category }}
                        </template>
                      </Column>
                      <Column
                        field="price"
                        header="Price"
                        headerStyle="text-align:center"
                        style="width: 55%"
                      >
                        <template #editor="slotProps">
                          <InputNumber
                            style="width: 100px"
                            id="price"
                            v-model="
                              slotProps.data[slotProps.column.props.field]
                            "
                            mode="currency"
                            currency="MYR"
                            locale="ms-MY"
                          />
                        </template>
                        <template #body="slotProps">
                          RM{{
                            slotProps.data.price.toLocaleString('ms-MY', {
                              style: 'decimal',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          }}
                        </template>
                      </Column>
                      <Column
                        header="Action"
                        style="width: 5%; min-width: 8rem; text-align: center"
                        field="action"
                      >
                        <template #body="slotProps">
                          <Button
                            icon="pi pi-times"
                            class="p-button-rounded p-button-danger p-button-outlined"
                            @click="deleteService(slotProps.data)"
                          />
                        </template>
                      </Column>
                      <template #empty> No records found. </template>
                    </DataTable>
                    <Button
                      type="button"
                      icon="pi pi-search"
                      label="Add Service"
                      @click="toggleServices"
                      aria:haspopup="true"
                      aria-controls="overlay_panel"
                      class="p-button-raised p-button-success"
                    />

                    <OverlayPanel
                      ref="op"
                      appendTo="body"
                      :showCloseIcon="true"
                      id="overlay_panel"
                      style="width: 450px"
                      :breakpoints="{ '960px': '75vw' }"
                    >
                      <DataTable
                        :value="serviceOptions"
                        :filters="filters5"
                        v-model:selection="selectedServices"
                        selectionMode="single"
                        :paginator="true"
                        :rows="3"
                        @row-select="onServiceSelect"
                      >
                        <div :v-for="service in device.technicalServiceDetails">
                          <InputText
                            type="hidden"
                            v-model="filters5['name'].value"
                            :value="service.name"
                          />
                        </div>
                        <Column
                          field="name"
                          header="Name"
                          style="width: 30%"
                        ></Column>
                        <Column
                          field="technicalServiceTypeCategory.name"
                          header="Category"
                          style="width: 30%"
                        ></Column
                        ><Column
                          field="defaultPrice"
                          header="Price"
                          sortable
                          style="width: 30%"
                        >
                          <template #body="slotProps">
                            RM{{
                              slotProps.data.defaultPrice.toLocaleString(
                                'ms-MY',
                                {
                                  style: 'decimal',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              )
                            }}
                          </template></Column
                        >
                        <template #empty> No records found. </template>
                      </DataTable>
                    </OverlayPanel>
                  </div>
                  <small
                    class="p-invalid"
                    v-if="
                      submitted &&
                      device.technicalServiceDetails.length == 0 &&
                      device.sparePartDetails.length == 0
                    "
                    >Atleast one from Service or Spare-Parts should be
                    choosen.</small
                  >
                </div>

                <div class="p-field">
                  <label>Spare-Parts</label>
                  <div>
                    <DataTable
                      :value="device.sparePartDetails"
                      dataKey="id"
                      :filters="filters4"
                      editMode="cell"
                      class="editable-cells-table"
                      responsiveLayout="scroll"
                    >
                      <Column
                        field="name"
                        header="Name"
                        headerStyle="text-align:center"
                        style="width: 20%"
                      >
                      </Column>
                      <Column
                        field="category"
                        header="Category"
                        headerStyle="text-align:center"
                        style="width: 20%"
                      >
                        <template #editor="slotProps">
                          <Dropdown
                            v-model="slotProps.data['category']"
                            :options="sparePartCategoryOptions"
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select a Type"
                          >
                            <template #option="slotProps">
                              <span
                                :class="
                                  'product-badge status-' +
                                  slotProps.option.name.toLowerCase()
                                "
                                >{{ slotProps.option.name }}</span
                              >
                            </template>
                          </Dropdown>
                        </template>
                        <template #body="slotProps">
                          {{ slotProps.data.category }}
                        </template>
                      </Column>
                      <Column
                        field="price"
                        header="Cost"
                        headerStyle="text-align:center"
                        style="width: 55%"
                      >
                        <template #editor="slotProps">
                          <InputNumber
                            id="price"
                            v-model="
                              slotProps.data[slotProps.column.props.field]
                            "
                            style="width: 100px"
                            mode="currency"
                            currency="MYR"
                            locale="ms-MY"
                          />
                        </template>
                        <template #body="slotProps">
                          RM{{
                            slotProps.data.price.toLocaleString('ms-MY', {
                              style: 'decimal',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          }}
                        </template>
                      </Column>
                      <Column
                        header="Action"
                        style="width: 5%; min-width: 8rem; text-align: center"
                        field="action"
                      >
                        <template #body="slotProps">
                          <Button
                            icon="pi pi-times"
                            @click="deleteSparePart(slotProps.data)"
                            class="p-button-rounded p-button-danger p-button-outlined"
                          />
                        </template>
                      </Column>
                      <template #empty> No records found. </template>
                    </DataTable>

                    <Button
                      type="button"
                      icon="pi pi-search"
                      label="Add Spare-Part"
                      @click="toggleSpareParts"
                      aria:haspopup="true"
                      aria-controls="overlay_panel"
                      class="p-button-raised p-button-success"
                    />

                    <OverlayPanel
                      ref="op2"
                      appendTo="body"
                      :showCloseIcon="true"
                      id="overlay_panel"
                      style="width: 450px"
                      :breakpoints="{ '960px': '75vw' }"
                    >
                      <DataTable
                        :value="sparePartOptions"
                        v-model:selection="selectedSpareParts"
                        selectionMode="single"
                        :paginator="true"
                        :rows="3"
                        @row-select="onSparePartSelect"
                      >
                        <Column
                          field="name"
                          header="Name"
                          sortable
                          style="width: 50%"
                        ></Column>
                        <Column
                          field="sparePartTypeCategory.name"
                          header="Category"
                          style="width: 30%"
                        ></Column
                        ><Column
                          field="defaultPrice"
                          header="Price"
                          sortable
                          style="width: 30%"
                          ><template #body="slotProps">
                            RM{{
                              slotProps.data.defaultPrice.toLocaleString(
                                'ms-MY',
                                {
                                  style: 'decimal',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              )
                            }}
                          </template></Column
                        >
                        <template #empty> No records found. </template>
                      </DataTable>
                    </OverlayPanel>
                  </div>

                  <small
                    class="p-invalid"
                    v-if="
                      submitted &&
                      device.technicalServiceDetails.length == 0 &&
                      device.sparePartDetails.length == 0
                    "
                    >Atleast one from Service or Spare-Parts should be
                    choosen.</small
                  >
                </div>

                <div class="p-field">
                  <label>Left in the Shop</label>
                  <div class="p-grid">
                    <div
                      class="p-col-12 p-md-4"
                      v-for="(deviceType, index) in deviceTypes"
                      v-bind:key="deviceType.id"
                    >
                      <div class="p-field-checkbox">
                        <Checkbox
                          :id="'checkOption' + index.toString()"
                          name="option"
                          :value="deviceType.name"
                          v-model="device.leftWiths"
                        />
                        <label :for="'checkOption' + index.toString()">{{
                          deviceType.name
                        }}</label>
                      </div>
                    </div>

                    <!-- <div class="p-col-12 p-md-4">
                    <div class="p-field-checkbox">
                      <Checkbox
                        id="checkOption1"
                        name="option"
                        value="Laptop"
                        v-model="device.leftWiths"
                      />
                      <label for="checkOption1">Laptop</label>
                    </div>
                  </div>

                  <div class="p-col-12 p-md-4">
                    <div class="p-field-checkbox">
                      <Checkbox
                        id="checkOption2"
                        name="option"
                        value="Monitor"
                        v-model="device.leftWiths"
                      />
                      <label for="checkOption1">Monitor</label>
                    </div>
                  </div>

                  <div class="p-col-12 p-md-4">
                    <div class="p-field-checkbox">
                      <Checkbox
                        id="checkOption3"
                        name="option"
                        value="CPU"
                        v-model="device.leftWiths"
                      />
                      <label for="checkOption1">CPU</label>
                    </div>
                  </div>
                  <div class="p-col-12 p-md-4">
                    <div class="p-field-checkbox">
                      <Checkbox
                        id="checkOption4"
                        name="option"
                        value="Adapter"
                        v-model="device.leftWiths"
                      />
                      <label for="checkOption1">Adapter</label>
                    </div>
                  </div>
                  <div class="p-col-12 p-md-4">
                    <div class="p-field-checkbox">
                      <Checkbox
                        id="checkOption5"
                        name="option"
                        value="Bag"
                        v-model="device.leftWiths"
                      />
                      <label for="checkOption6">Bag</label>
                    </div>
                  </div> -->
                  </div>

                  <small
                    class="p-invalid"
                    v-if="submitted && device.leftWiths.length == 0"
                    >Please choose atleast one.</small
                  >
                </div>
                <div class="p-field">
                  <label for="picture">Devices Picture</label>
                  <picture-input
                    ref="pictureInput"
                    @change="onChange"
                    width="400"
                    height="400"
                    margin="16"
                    accept="image/jpeg,image/png"
                    size="5"
                    buttonClass="btn"
                    :customStrings="{
                      upload: '<h1>Bummer!</h1>',
                      drag: 'Drag a jpeg/png here or click here to browse an image',
                    }"
                  >
                  </picture-input>

                  <small
                    class="p-invalid"
                    v-if="submitted && device.image == null"
                    >Device image is required.</small
                  >
                </div>
                <div class="p-field">
                  <label for="description"
                    >Device Description (For admin, warehouse and your
                    reference)</label
                  >
                  <Textarea
                    id="description"
                    v-model.trim="device.description"
                    required="true"
                    :class="{ 'p-invalid': submitted && !device.description }"
                  />
                  <small
                    class="p-invalid"
                    v-if="submitted && !device.description"
                    >Device description is required.</small
                  >
                </div>
              </div>
              <error v-if="errorDevice" :error="error" />
              <template v-if="deviceCategory.name != null" #footer>
                <div
                  class="spinner-border text-primary"
                  v-if="loadingPostDevice"
                ></div>
                <Button
                  :disabled="loadingPostDevice"
                  label="Add"
                  icon="pi pi-check"
                  class="p-button-text"
                  @click="
                    hasSpecialistRepair && hasPreOrder
                      ? saveDevice()
                      : triggerSaveDeviceConfirmationDialog()
                  "
                />
              </template>
            </Dialog>

            <small
              class="p-invalid"
              v-if="submitted && receipt.deviceDetails.length == 0"
              >Please insert atleast one device.</small
            >
          </div>

          <div class="p-formgrid p-grid">
            <div class="p-field p-col">
              <label for="expectedFinishDate"
                >Expected<br />Finish Datetime</label
              >
              <Calendar
                id="expectedFinishDate"
                v-model="receipt.expectedFinishDate"
                :minDate="new Date()"
                :manualInput="false"
                dateFormat="dd-mm-yy"
                :showTime="true"
                :class="{
                  'p-invalid': submitted && !receipt.expectedFinishDate,
                }"
              />

              <small
                class="p-invalid"
                v-if="submitted && !receipt.expectedFinishDate"
                >Expected finish datetime is required.</small
              >
            </div>
            <!-- <div class="p-field p-col">
              <label for="expectedFinishTime">Expected<br />Finish Time</label>
              <Calendar
                id="expectedFinishTime"
                v-model="receipt.expectedFinishTime"
                :timeOnly="true"
                :manualInput="false"
                :class="{
                  'p-invalid': submitted && !receipt.expectedFinishTime,
                }"
              />
              <small
                class="p-invalid"
                v-if="submitted && !receipt.expectedFinishTime"
                >Expected finish time is required.</small
              >
            </div> -->
            <div class="p-field p-col">
              <label for="price">Agreed<br />Total Cost</label>
              <InputNumber
                id="price"
                v-model="agreedTotalPrice"
                mode="currency"
                currency="MYR"
                locale="ms-MY"
                :class="{
                  'p-invalid': submitted && receipt.totalPrice == null,
                }"
                disabled
              />
              <small
                class="p-invalid"
                v-if="submitted && receipt.totalPrice == null"
                >Total price is required.</small
              >
            </div>
          </div>
          <error v-if="errorReceipt" :error="error" />
          <template #footer>
            <div
              class="spinner-border text-primary"
              v-if="loadingPostReceipt"
            ></div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              :disabled="loadingPostReceipt"
              label="Save"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveReceipt"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteReceiptDialog"
          :style="{ width: '475px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="receipt"
              >Are you sure you want to delete <b>{{ receipt.customerName }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteReceiptDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteReceipt"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteReceiptsDialog"
          :style="{ width: '475px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="receipt"
              >Are you sure you want to delete the selected receipts?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteReceiptsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedReceipts"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteDeviceDialog"
          :style="{ width: '475px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="device"
              >Are you sure you want to delete <b>{{ device.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteDeviceDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteDevice"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="warningDialog"
          :style="{ width: '475px' }"
          header="Reminder"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="receipt"
              >This device (<b
                >[{{ device.color.name }}] {{ device.brand.name }}
                {{ device.model }}</b
              >) have unsettled service/spare-part status. Please recheck before
              contact/handover to its owner.</span
            >
          </div>
          <template #footer>
            <Button
              label="Ok"
              icon="pi pi-check"
              class="p-button-text"
              @click="warningDialog = false"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="saveDeviceDialog"
          :style="{ width: '475px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="receipt"
              >Are you sure this device doesn't need
              <b
                >{{ !hasSpecialistRepair ? 'Specialist Repair' : null }}
                {{ !hasSpecialistRepair && !hasPreOrder ? 'and' : null }}
                {{ !hasPreOrder ? 'Pre-Order Spare-part' : null }}</b
              >? If it does need it, you have to choose it in
              <b
                >{{ !hasSpecialistRepair ? 'Service' : null }}
                {{ !hasSpecialistRepair && !hasPreOrder ? 'and' : null }}
                {{ !hasPreOrder ? 'Spare-Part' : null }}</b
              >
              category to be noticed by Warehouse Officer and been recorded. If
              it really doesn't necessary, ignore this message and click yes.
            </span>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="saveDeviceDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveDevice"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="completionDialog"
          :style="{ width: '475px' }"
          header="Completion Image"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="receipt"
              >Snap the picture of device (<b
                >[{{ device.color.name }}] {{ device.brand.name }}
                {{ device.model }}</b
              >), its signed receipt, and its owner in one picture for
              confirmation.</span
            >
          </div>
          <picture-input
            ref="pictureInput"
            @change="onChangeCompletion"
            width="400"
            height="400"
            margin="16"
            accept="image/jpeg,image/png"
            size="5"
            buttonClass="btn"
            :customStrings="{
              upload: '<h1>Bummer!</h1>',
              drag: 'Drag a jpeg/png here or click here to browse an image',
            }"
          >
          </picture-input>
          <small
            class="p-invalid"
            v-if="completionSubmitted && completionImage == null"
            >Completion image is required.</small
          >
          <template #footer>
            <div
              class="spinner-border text-primary"
              v-if="loadingCompletionImage"
            ></div>
            <Button
              :disabled="loadSubmitCompletion"
              label="Confirm"
              icon="pi pi-check"
              class="p-button-text"
              @click="completionFunction()"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import PictureInput from 'vue-picture-input';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { jsPDF } from 'jspdf';
import Error from '../components/Error';
import Calendar from 'primevue/calendar';
import Column from 'primevue/column';

import Checkbox from 'primevue/checkbox';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';

import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';

import InputMask from 'primevue/inputmask';

import InputNumber from 'primevue/inputnumber';

import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';

import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';

import Toolbar from 'primevue/toolbar';

import Timeline from 'primevue/timeline';

export default {
  components: {
    Calendar,
    Checkbox,
    Column,
    DataTable,
    Dialog,
    Dropdown,
    FileUpload,
    InputMask,
    InputNumber,
    InputText,
    MultiSelect,
    OverlayPanel,
    RadioButton,
    Textarea,
    Toast,
    Toolbar,
    Timeline,
    PictureInput,
    Error,
  },
  data() {
    return {
      Buffer,
      alternative: true,
      loadingGetReceipts: true,
      loadingGetDevices: true,
      loadingGetServices: false,
      loadingGetSpareParts: false,
      loadingGetBrandOptions: false,
      loadingGetDefectOptions: false,
      loadingGetDeviceTypes: false,
      loadingGetColorOptions: true,
      loadingGetServiceCategories: true,
      loadingGetSparePartCategories: true,
      loadingGetServiceStatusTechs: true,
      loadingGetDeviceCategories: true,
      loadingPostDevice: false,
      loadingPostReceipt: false,
      loadingGetDevices2: false,
      loadingPrint: false,
      loadSubmitCompletion: false,
      completionSubmitted: false,
      // loadingFilter:false,
      searchFilterbyCategory: 1,
      searchFilterbyCategoryOptions: [1, 2, 3, 4, 5, 6],
      error: '',
      errorDevice: false,
      errorReceipt: false,
      // notAllDoneYet:true,

      filters5: {
        name: { value: '', keys: ['name'] },
      },

      updateDeviceStatusDialog: false,
      warningDialog: false,
      saveDeviceDialog: false,
      completionDialog: false,
      receiptAddInfoDialog: false,
      receiptDialog: false,
      deviceDialog: false,
      deleteReceiptDialog: false,
      deleteDeviceDialog: false,
      deleteReceiptsDialog: false,
      loadingCompletionImage: false,
      receipt: {},
      device: {},
      service: {},
      technicalServiceDetail: {},
      technicalServiceDetails: [],
      sparePartDetail: {},
      sparePartDetails: [],
      sparePart: {},
      expandedRowsReceipt: [],
      selectedServices: null,
      selectedSpareParts: null,
      brand: null,
      color: null,
      checkboxValue: null,
      serviceStatusTech: null,
      filters: null,
      filters1: {},
      filters2: {},
      filters3: {},
      filters4: {},
      submitted: false,
      submittedDevice: false,
      minDate: null,
      maxDate: null,
      invalidDates: null,
      imgLoaded: false,
      searchByString: '',
      completionImage: null,
      completionIsSucceed: null,

      screenHeight: window.screen.height,
      screenWidth: window.screen.width,

      browserHeight: window.innerHeight,
      browserWidth: window.innerWidth,
      serviceUsedStatus: 1,
      lazyParams: {},
      searchBy: { id: 0, name: 'Search By None' },
      searchByOptions: [
        { id: 0, name: 'Search By None' },
        { id: 1, name: 'Contact' },
        { id: 2, name: 'Receipt Code' },
      ],
      searchFilterbyOutlet: { id: 0, name: 'All Outlets' },
      loadingOutlets: true,
      deviceCategory: { name: null },
    };
  },
  originalRows: null,
  created() {
    //datetime declaration
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = month === 0 ? 11 : month - 1;
    let prevYear = prevMonth === 11 ? year - 1 : year;
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = nextMonth === 0 ? year + 1 : year;
    this.minDate = new Date();
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);
    this.maxDate = new Date();
    this.maxDate.setMonth(nextMonth);
    this.maxDate.setFullYear(nextYear);

    let invalidDate = new Date();
    invalidDate.setDate(today.getDate() - 1);
    this.invalidDates = [today, invalidDate];

    this.initFilters();
  },
  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      // filters: this.filters,
      ref: 'dt',
      // groupId: localStorage.getItem("counterGroupId"),
      // counterId: localStorage.getItem("counterId"),
    };

    this.loadLazyData();
    let self = this;
    // self.$store.dispatch("getReceipts").then(() => {
    //   this.loadingGetReceipts = false;
    // });
    // self.$store.dispatch("device/getDevices").then(() => {
    //   this.loadingGetDevices = false;
    // });

    self.$store.dispatch('device/getServiceCategories').then(() => {
      this.loadingGetServiceCategories = false;
    });
    self.$store.dispatch('device/getSparePartCategories').then(() => {
      this.loadingGetSparePartCategories = false;
    });
    self.$store.dispatch('device/getColorOptions').then(() => {
      this.loadingGetColorOptions = false;
    });
    self.$store.dispatch('device/getServiceStatusTechs').then(() => {
      this.loadingGetServiceStatusTechs = false;
    });
    self.$store.dispatch('device/getDeviceCategories').then(() => {
      this.loadingGetDeviceCategories = false;
    });
    if (this.isSuperAdmin) {
      this.$store
        .dispatch('staff/getOutletsOnly', {
          organizationId: this.staff.outlet.organizationId,
        })
        .then(() => (this.loadingOutlets = false));
    }
  },
  computed: {
    staff() {
      let staff = this.$store.getters['logged/getStaff'];
      return staff.data.result;
    },
    isSuperAdmin() {
      return (
        this.staff.staffPosition.name == 'HQ Administrator' ||
        this.staff.staffPosition.name == 'Kerawang Administrator'
      );
    },
    searchFilterbyOutletOptions() {
      return [{ id: 0, name: 'All Outlets' }, ...this.outlets];
    },

    outlets() {
      let outlets = this.$store.getters['staff/outletsOnly'];
      return outlets;
    },
    staffPosition() {
      return localStorage.getItem('staffPosition');
    },
    receipts() {
      return this.$store.getters['receipt/receipts'];
    },
    totalReceipts() {
      return this.$store.getters['receipt/totalReceipts'];
    },
    devices() {
      return this.$store.getters['device/devices'];
    },
    services() {
      return this.$store.getters['device/services'];
    },
    spareParts() {
      return this.$store.getters['device/spareParts'];
    },
    brandOptions() {
      return this.$store.getters['device/brandOptions'];
    },
    colorOptions() {
      return this.$store.getters['device/colorOptions'];
    },
    serviceOptions() {
      return this.$store.getters['device/serviceOptions'];
    },
    sparePartOptions() {
      return this.$store.getters['device/sparePartOptions'];
    },
    serviceNameOptions() {
      return this.$store.getters['device/serviceNameOptions'];
    },
    serviceCategoryOptions() {
      return this.$store.getters['device/serviceCategoryOptions'];
    },
    sparePartTypeOptions() {
      return this.$store.getters['device/sparePartTypeOptions'];
    },
    sparePartCategoryOptions() {
      return this.$store.getters['device/sparePartCategoryOptions'];
    },
    defectOptions() {
      return this.$store.getters['device/defectOptions'];
    },
    serviceStatusTechs() {
      return this.$store.getters['device/serviceStatusTechs'];
    },
    deviceTypes() {
      return this.$store.getters['device/deviceTypes'];
    },
    deviceCategories() {
      return this.$store.getters['device/deviceCategories'];
    },
    hasSpecialistRepair() {
      return this.device.technicalServiceDetails.find(
        (x) => x.category == 'Specialist-Repair',
      )
        ? true
        : false;
    },
    hasPreOrder() {
      return this.device.sparePartDetails.find((x) => x.category == 'Pre-Order')
        ? true
        : false;
    },
    agreedTotalPrice() {
      // return this.receipt.totalPrice;
      // technicalServiceDetails sparePartDetails
      return this.receipt.deviceDetails.reduce(
        (total, value) =>
          total +
          value.technicalServiceDetails.reduce(
            (total, value) => total + value.price,
            0,
          ) +
          value.sparePartDetails.reduce(
            (total, value) => total + value.price,
            0,
          ),
        0,
      );
    },
    notAllDoneYet() {
      let status =
        this.serviceStatusTechFilters.filter(function (x) {
          return x.statusStage < x.statusList.length - 2;
        }).length == 0
          ? false
          : true;

      return status;
    },
    events() {
      return (index, serviceStatusTech) => {
        index = index - 1;
        index = index * 5;
        if (serviceStatusTech.statusList.length <= 5) {
          return serviceStatusTech.statusList;
        } else {
          var temp = [];
          for (var i = index; i <= index + 4; i++) {
            if (serviceStatusTech.statusList[i]) {
              serviceStatusTech.statusList[i]
                .split(/((?:\w+ ){1})/g)
                .filter(Boolean)
                .join('\n');
              temp.push(serviceStatusTech.statusList[i]);
            } else {
              break;
            }
          }
          return temp;
        }
      };
    },
    serviceStatusTechFilters() {
      var temp;
      var temp2 = [];
      for (var i = 0; i < this.device.technicalServiceDetails.length; i++) {
        for (var x = 0; x < this.serviceStatusTechs.length; x++) {
          temp = {};
          temp['name'] = null;
          temp['category'] = null;
          temp['statusStage'] = null;
          temp['statusList'] = [];
          temp['isAdmin'] = [];
          temp['isSucceed'] = null;
          var category1 =
            this.device.technicalServiceDetails[i].category.toUpperCase();
          var category2 = this.serviceStatusTechs[x].category.toUpperCase();
          if (category1 === category2) {
            temp.name = this.device.technicalServiceDetails[i].name;
            temp.statusStage =
              this.device.technicalServiceDetails[i].statusStage;
            temp.isSucceed = this.device.technicalServiceDetails[i].isSucceed; // need as array
            temp.category = this.serviceStatusTechs[x].category;
            temp.statusList = this.serviceStatusTechs[x].statusList;
            temp.isAdmin = this.serviceStatusTechs[x].isAdmin;
            temp2.push(temp);
            break;
          }
        }
      }
      for (var j = 0; j < this.device.sparePartDetails.length; j++) {
        for (var k = 0; k < this.serviceStatusTechs.length; k++) {
          temp = {};
          temp['name'] = null;
          temp['category'] = null;
          temp['statusList'] = [];
          temp['isAdmin'] = [];
          temp['isSucceed'] = null;
          category1 = this.device.sparePartDetails[j].category.toUpperCase();
          category2 = this.serviceStatusTechs[k].category.toUpperCase();
          if (category1 === category2) {
            temp.name = this.device.sparePartDetails[j].name;
            temp.statusStage = this.device.sparePartDetails[j].statusStage;
            temp.isSucceed = this.device.sparePartDetails[j].isSucceed; // need as array
            temp.category = this.serviceStatusTechs[k].category;
            temp.statusList = this.serviceStatusTechs[k].statusList;
            temp.isAdmin = this.serviceStatusTechs[k].isAdmin;
            temp2.push(temp);
            break;
          }
        }
      }
      return temp2;
    },
  },
  watch: {
    searchBy: function (val) {
      if (val) {
        this.searchByString = '';
      }
    },
    deviceCategory: function (val) {
      if (val) {
        let self = this;

        this.device['defects'] = [];
        this.device['leftWiths'] = [];
        this.device['technicalServiceDetails'] = [];
        this.device['sparePartDetails'] = [];
        this.device['serviceOptions'] = [];
        this.device['sparePartOptions'] = [];
        this.device['brand'] = { name: null };

        this.device.serviceOptions = this.serviceOptions;
        this.device.sparePartOptions = this.sparePartOptions;

        this.loadingGetServices = true;
        this.loadingGetSpareParts = true;
        this.loadingGetBrandOptions = true;
        this.loadingGetDefectOptions = true;
        this.loadingGetDeviceTypes = true;

        self.$store
          .dispatch('device/getServices', { deviceCategoryId: val.id })
          .then(() => {
            this.loadingGetServices = false;
          });
        self.$store
          .dispatch('device/getSpareParts', { deviceCategoryId: val.id })
          .then(() => {
            this.loadingGetSpareParts = false;
          });
        self.$store
          .dispatch('device/getBrandOptions', { deviceCategoryId: val.id })
          .then(() => {
            this.loadingGetBrandOptions = false;
          });
        self.$store
          .dispatch('device/getDefectOptions', { deviceCategoryId: val.id })
          .then(() => {
            this.loadingGetDefectOptions = false;
          });
        self.$store
          .dispatch('device/getDeviceTypes', { deviceCategoryId: val.id })
          .then(() => {
            this.loadingGetDeviceTypes = false;
          });
      }
    },
  },
  methods: {
    async completionFunction() {
      this.loadSubmitCompletion = true;
      this.completionSubmitted = false;
      if (this.completionImage != null) {
        this.loadingCompletionImage = true;
        await this.$store
          .dispatch('receipt/putCompletionImage', {
            deviceId: this.device.id,
            completionImage: this.completionImage,
          })
          .then(() => {
            this.updateDeviceStatusTickCross(
              this.serviceStatusTech,
              this.completionIsSucceed,
            );
            this.loadSubmitCompletion = false;
            this.completionDialog = false;
            this.loadingCompletionImage = false;
            var theDevice;
            this.device.completionImage = this.receipts.find((x) => {
              theDevice = x.deviceDetails.findIndex(
                (y) => y.id == this.device.id,
              );
              return theDevice >= 0 ? true : false;
            }).deviceDetails[theDevice].completionImage;
          });
      }
      this.completionSubmitted = true;
    },
    imgLoadedConfirmed() {
      this.imgLoaded = true;
    },
    // resetDeviceCategory() {
    //   // this.device.brand = null;
    //   // this.device.type = null;
    //   // this.device.defects=[];
    //   // this.device.technicalServiceDetails=[];
    //   // this.device.sparePartDetails=[];
    //   // this.device.leftWiths=[];
    //   console.log("Test");
    //   this.device["defects"] = [];
    //   this.device["leftWiths"] = [];
    //   this.device["technicalServiceDetails"] = [];
    //   this.device["sparePartDetails"] = [];
    //   this.device["serviceOptions"] = [];
    //   this.device["sparePartOptions"] = [];
    //   this.device["brand"] = { name: null };

    //   this.device.serviceOptions = this.serviceOptions;
    //   this.device.sparePartOptions = this.sparePartOptions;
    // },
    searchFilterbyCategoryName(value) {
      let filterString = '';
      switch (value) {
        case 1:
          filterString = 'All Categories';
          break;
        case 2:
          filterString = 'No Action Yet';
          break;
        case 3:
          filterString = 'In Progress';
          break;
        case 4:
          filterString = 'Completed';
          break;
        case 5:
          filterString = 'Wait For Cust (WFC)';
          break;
        case 6:
          filterString = 'Excl. WFC';
          break;
        default:
          filterString = '-';
      }
      return filterString;
    },
    loadLazyData() {
      // this.loading = true;
      this.loadingGetReceipts = true;
      this.$store
        .dispatch('receipt/getReceipts', {
          lazyParams: this.lazyParams,
          searchFilterbyCategory: this.searchFilterbyCategory,
          searchById: this.searchBy.id,
          searchByString: this.searchByString,
          outletId: this.searchFilterbyOutlet.id,
        })
        .then(() => {
          this.loadingGetReceipts = false;
        });
      // this.loading = false;
    },
    onPage(event) {
      this.lazyParams = event;

      this.loadLazyData();
      clearInterval(this.interval);
    },
    onSort(event) {
      this.lazyParams = event;
      this.loadLazyData();
    },
    initialDate(date) {
      var temp = new Date(date);
      var newDate = this.dateCustom(temp);
      return newDate;
    },
    expectedFinishDateTime(date, time) {
      var tempDate = new Date(date);
      var tempTime = new Date(time);
      tempDate.setHours(tempTime.getHours());
      tempDate.setMinutes(tempTime.getMinutes());
      var newDate = this.dateCustom(tempDate);
      return newDate;
    },
    dateCustom(date) {
      var dateCustom =
        this.formatTwoZeros(date.getDate()) +
        '/' +
        this.formatTwoZeros(date.getMonth() + 1) +
        '/' +
        date.getFullYear() +
        ' ' +
        this.formatTwoZeros(date.getHours()) +
        ':' +
        this.formatTwoZeros(date.getMinutes()) +
        ' (' +
        this.formatDay(date.getDay()) +
        ')';
      return dateCustom;
    },
    formatTwoZeros(value) {
      if (value < 10) {
        value = '0' + value;
      }
      return value;
    },

    formatDay(value) {
      let dayString = '';
      switch (value) {
        case 0:
          dayString = 'Sun';
          break;
        case 1:
          dayString = 'Mon';
          break;
        case 2:
          dayString = 'Tue';
          break;
        case 3:
          dayString = 'Wed';
          break;
        case 4:
          dayString = 'Thu';
          break;
        case 5:
          dayString = 'Fri';
          break;
        case 6:
          dayString = 'Sat';
          break;
        default:
          dayString = '-';
      }
      return dayString;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    onRowExpand(event) {
      this.$toast.add({
        severity: 'info',
        summary: 'Receipt Expanded',
        detail: event.data.name,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: 'success',
        summary: 'Receipt Collapsed',
        detail: event.data.name,
        life: 3000,
      });
    },
    rowClass() {
      return 'row-devices';
    },
    expandAll() {
      this.expandedRowsReceipt = this.receipts.filter((p) => p.id);
      this.$toast.add({
        severity: 'success',
        summary: 'All Rows Expanded',
        life: 3000,
      });
    },
    collapseAll() {
      this.expandedRowsReceipt = null;
      this.$toast.add({
        severity: 'success',
        summary: 'All Rows Collapsed',
        life: 3000,
      });
    },
    onUpload() {
      this.$toast.add({
        severity: 'info',
        summary: 'Success',
        detail: 'File Uploaded',
        life: 3000,
      });
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'MYR',
        });
      return;
    },
    openNew() {
      var d = new Date();
      this.receipt = {};
      this.receipt.receiverName = localStorage.getItem('userName');
      var date;
      var month;
      var fullYear = d.getFullYear();
      if (d.getDate() < 10) {
        date = '0' + d.getDate();
      } else {
        date = d.getDate();
      }
      if (d.getMonth() < 10) {
        month = '0' + (d.getMonth() + 1);
      } else {
        month = d.getMonth() + 1;
      }
      this.receipt.initialDate = date + '-' + month + '-' + fullYear;
      // console.log("toISOString: " + d.toISOString());
      // console.log("JSON: " + d.toJSON().replace("Z", ""));
      // console.log("toLocaleDateString: " + d.toLocaleDateString());
      // console.log("toLocaleString: " + d.toLocaleString());
      this.receipt['deviceDetails'] = [];
      this.receipt['totalPrice'] = 0;
      this.submitted = false;
      this.receiptDialog = true;
    },
    hideDialog() {
      this.receiptDialog = false;
      this.submitted = false;
    },
    openAddDevice() {
      this.device = {};
      this.device['code'] = null;
      this.device['defects'] = [];
      this.device['leftWiths'] = [];
      this.device['technicalServiceDetails'] = [];
      this.device['sparePartDetails'] = [];
      this.device['serviceOptions'] = [];
      this.device['sparePartOptions'] = [];
      this.device['image'] = null;
      this.device['brand'] = { name: null };
      this.device['color'] = { name: null };
      this.submittedDevice = false;
      this.deviceDialog = true;
      this.device.serviceOptions = this.serviceOptions;
      this.device.sparePartOptions = this.sparePartOptions;
    },
    addService() {},
    toggleServices(event) {
      this.$refs.op.toggle(event);
    },
    toggleSpareParts(event) {
      this.$refs.op2.toggle(event);
    },
    onServiceSelect(event) {
      this.$refs.op.hide();
      if (this.device.technicalServiceDetails != []) {
        this.technicalServiceDetails = this.device.technicalServiceDetails;
      }
      this.technicalServiceDetail.id = this.createId();
      this.technicalServiceDetail.name = event.data.name;
      this.technicalServiceDetail.category =
        event.data.technicalServiceTypeCategory.name;
      this.technicalServiceDetail.price = event.data.defaultPrice;
      this.technicalServiceDetail.statusStage = 1;
      this.technicalServiceDetails.push(this.technicalServiceDetail);
      this.device.technicalServiceDetails = this.technicalServiceDetails;
      this.technicalServiceDetail = {};

      this.device.serviceOptions = this.serviceOptions.filter(
        (val) => val.id !== event.data.id,
      );
      this.$toast.add({
        severity: 'info',
        summary: 'Service Selected',
        detail: event.data.name,
        life: 3000,
      });
    },
    onSparePartSelect(event) {
      this.$refs.op2.hide();
      if (this.device.sparePartDetails != []) {
        this.sparePartDetails = this.device.sparePartDetails;
      }
      this.sparePartDetail.id = this.createId();
      this.sparePartDetail.name = event.data.name;
      this.sparePartDetail.category = event.data.sparePartTypeCategory.name;
      this.sparePartDetail.price = event.data.defaultPrice;
      this.sparePartDetail.statusStage = 1;
      this.sparePartDetails.push(this.sparePartDetail);
      this.device.sparePartDetails = this.sparePartDetails;
      this.sparePartDetail = {};

      this.$toast.add({
        severity: 'info',
        summary: 'Spare-Part Selected',
        detail: event.data.name,
        life: 3000,
      });
    },
    onChange(image) {
      if (image) {
        console.log('Picture loaded.');
        this.device.image = image;
      } else {
        console.log('FileReader API not supported: use the <form>, Luke!');
      }
    },
    onChangeCompletion(image) {
      if (image) {
        console.log('Picture loaded.');
        this.completionImage = image;
      } else {
        console.log('FileReader API not supported: use the <form>, Luke!');
      }
    },
    async saveDevice() {
      this.submitted = true;
      this.errorDevice = false;
      this.technicalServiceDetails = [];
      this.sparePartDetails = [];
      this.loadingPostDevice = true;
      this.saveDeviceDialog = false;

      if (
        this.device.brand.name == null ||
        this.device.color.name == null ||
        this.device.model == null ||
        this.device.type == null ||
        this.device.defects.length == 0 ||
        (this.device.technicalServiceDetails.length == 0 &&
          this.device.sparePartDetails.length == 0) ||
        this.device.leftWiths.length == 0 ||
        this.device.image == null ||
        this.device.description == null
      ) {
        // if (
        //   this.device["brand"] == { name: null } ||
        //   this.device["color"] == { name: null } ||
        //   this.device.model == null ||
        //   this.device.type == null ||
        //   this.device.defects.length == 0 ||
        //   (this.device.technicalServiceDetails.length == 0 &&
        //     this.device.sparePartDetails.length == 0) ||
        //   this.device.leftWiths.length == 0 ||
        //   this.image == null ||
        //   this.device.description == null
        // ) {
        console.log('Not Submitted');
        this.errorDevice = true;

        this.loadingPostDevice = false;
        this.error = 'Please fill up all fields!';
      } else {
        this.device.technicalServiceDetails.forEach((x) => {
          x.isSucceed = [true];
        });
        this.device.sparePartDetails.forEach((x) => {
          x.isSucceed = [true];
        });
        console.log('Submitted');
        this.errorDevice = false;
        if (this.device.model.trim()) {
          if (this.device.code != null) {
            // this.device.image = this.image;
            this.receipt.deviceDetails[
              this.findIndexByCodeDevice(this.device.code)
            ] = this.device;
            this.$toast.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'Device Updated',
              life: 3000,
            });
          } else {
            this.device.id = this.createId();
            // this is the reason why can't update device - problem
            delete this.device.id;
            this.device.code = this.createId();
            // this.device.image = this.image;
            // this.device.image = this.b64toBlob(this.image);
            // this.device.image = await new dataURLToBlob(this.image);

            //Please note that if the datatype of the input doesn't match the API/database, it will send null value
            for (
              var i = 0;
              i < this.device.technicalServiceDetails.length;
              i++
            ) {
              // this.receipt.totalPrice +=
              //   this.device.technicalServiceDetails[i].price;
              Object.defineProperty(
                this.device.technicalServiceDetails[i],
                'id',
                { configurable: true },
              );
              delete this.device.technicalServiceDetails[i].id;
            }
            for (i = 0; i < this.device.sparePartDetails.length; i++) {
              // this.receipt.totalPrice += this.device.sparePartDetails[i].price;
              Object.defineProperty(this.device.sparePartDetails[i], 'id', {
                configurable: true,
              });

              delete this.device.sparePartDetails[i].id;
            }
            await this.receipt.deviceDetails.push(this.device);
            this.$toast.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'Device Created',
              life: 3000,
            });
          }
        }
        this.loadingPostDevice = false;
        this.deviceDialog = false;
        this.device = {};
      }
    },
    async saveReceipt() {
      this.submitted = true;
      this.loadingPostReceipt = true;
      this.errorReceipt = false;

      if (
        this.receipt.customerName == null ||
        this.receipt.customerContact == null ||
        this.receipt.receiverName == null ||
        this.receipt.initialDate == null ||
        this.receipt.deviceDetails.length == 0 ||
        this.receipt.expectedFinishDate == null ||
        this.receipt.totalPrice == null
      ) {
        this.loadingPostReceipt = false;

        console.log('Not Submitted');
        this.errorReceipt = true;
        this.error = 'Please fill up all fields!';
      } else {
        console.log('Submitted');
        this.errorReceipt = false;
        if (this.receipt.customerName.trim()) {
          if (this.receipt.id) {
            this.receipts[this.findIndexByIdReceipt(this.receipt.id)] =
              this.receipt;
            this.$toast.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'Receipt Updated',
              life: 3000,
            });
            this.loadingPostReceipt = false;
          } else {
            this.receipt.id = this.createId();
            this.receipt.receiptCode = this.createId();
            // string variable="" is the same as string variable = null
            this.receipt.description = '...';
            this.receipt.imageReceipt = 'receipt-placeholder.svg';
            this.receipt.receiptServiceStatus = 'No Action Yet';
            this.receipt.tracking_code = 'ABC123456786';
            this.receipt.serviceDuration = null;

            var tempInitialDate = new Date();
            var tempInitialTime = new Date();
            this.receipt.totalPrice = this.agreedTotalPrice;
            // this.receipt.expectedFinishDate =
            //   this.receipt.expectedFinishDate.toUTCString();
            // this.receipt.expectedFinishTime =
            //   this.receipt.expectedFinishTime.toUTCString();
            this.receipt.initialDate = tempInitialDate.toUTCString();
            this.receipt.initialTime = tempInitialTime.toUTCString();

            this.receipt.initialDate = tempInitialDate.toJSON();
            this.receipt.initialTime = tempInitialTime.toJSON();
            this.receipt.expectedFinishTime = this.receipt.expectedFinishDate;
            this.receipt.realFinishDate = null;
            this.receipt.realFinishTime = null;
            this.$toast.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'Receipt Created',
              life: 3000,
            });

            Object.defineProperty(this.receipt, 'id', { configurable: true });
            delete this.receipt.id;
            var postReceipt = this.receipt;
            await this.$store
              .dispatch('receipt/postReceipt', {
                receipt: postReceipt,
              })
              .then(() => {
                this.loadingPostReceipt = false;
              });
          }

          this.receiptDialog = false;
          this.receipt = {};
        }
      }
    },
    updateDeviceStatus(device) {
      this.device = { ...device };
      this.updateDeviceStatusDialog = true;
    },
    triggerWarningDialog() {
      this.warningDialog = true;
    },
    triggerSaveDeviceConfirmationDialog() {
      this.saveDeviceDialog = true;
    },
    triggerCompletionDialog(serviceStatusTech, completionStatus) {
      this.serviceStatusTech = { ...serviceStatusTech };
      this.completionIsSucceed = completionStatus;
      this.completionDialog = true;
    },
    async updateDeviceStatusTickCross(serviceStatusTech, succeed) {
      var type = 0;
      var statusStage = 0;
      var conditionOfStatusStage;
      for (var i = 0; i < this.device.sparePartDetails.length; i++) {
        conditionOfStatusStage =
          serviceStatusTech.statusStage + 1 >=
          serviceStatusTech.statusList.length - 1
            ? this.serviceStatusTechFilters[
                i + this.device.technicalServiceDetails.length
              ]
            : serviceStatusTech;
        if (
          this.device.sparePartDetails[i].name == conditionOfStatusStage.name &&
          this.device.sparePartDetails[i].statusStage <
            conditionOfStatusStage.statusList.length
        ) {
          //Declaring statusStage here is necessary to avoid additional stage added to it
          statusStage = conditionOfStatusStage.statusStage + 1;
          console.log(
            'Status Stage sparePart name:' + conditionOfStatusStage.name,
          );
          console.log(
            'Status Stage sparePart category:' +
              conditionOfStatusStage.category,
          );
          console.log('Status Stage sparePart newStatusStage:' + statusStage);
          console.log(
            'Status Stage sparePart statusList.length:' +
              conditionOfStatusStage.statusList.length,
          );
          type = 1;
          this.device.sparePartDetails[i].statusStage++;
          this.device.sparePartDetails[i].isSucceed.push(succeed);
          this.$toast.add({
            severity: 'success',
            summary: 'Successful',
            detail: 'Status Updated',
            life: 3000,
          });
          await this.$store.dispatch('receipt/postNewProgress', {
            deviceId: this.device.id,
            name: conditionOfStatusStage.name,
            category: conditionOfStatusStage.category,
            type: 'sparePart',
            newStatusStage: statusStage,
            maxStatusStage: conditionOfStatusStage.statusList.length,
            succeed: succeed,
          });
        }
      }
      for (i = 0; i < this.device.technicalServiceDetails.length; i++) {
        conditionOfStatusStage =
          serviceStatusTech.statusStage + 1 >=
          serviceStatusTech.statusList.length - 1
            ? this.serviceStatusTechFilters[i]
            : serviceStatusTech;
        if (
          this.device.technicalServiceDetails[i].name ==
            conditionOfStatusStage.name &&
          this.device.technicalServiceDetails[i].statusStage <
            conditionOfStatusStage.statusList.length
        ) {
          //Declaring statusStage here is necessary to avoid additional stage added to it
          statusStage = conditionOfStatusStage.statusStage + 1;
          console.log(
            'Status Stage technicalService name:' + conditionOfStatusStage.name,
          );
          console.log(
            'Status Stage technicalService category:' +
              conditionOfStatusStage.category,
          );
          console.log(
            'Status Stage technicalService newStatusStage:' + statusStage,
          );
          console.log(
            'Status Stage technicalService statusList.length:' +
              conditionOfStatusStage.statusList.length,
          );
          if (type == 1) {
            type = 3;
          } else {
            type = 2;
          }
          this.device.technicalServiceDetails[i].statusStage++;
          this.device.technicalServiceDetails[i].isSucceed.push(succeed);
          this.$toast.add({
            severity: 'success',
            summary: 'Successful',
            detail: 'Status Updated',
            life: 3000,
          });

          await this.$store.dispatch('receipt/postNewProgress', {
            deviceId: this.device.id,
            name: conditionOfStatusStage.name,
            category: conditionOfStatusStage.category,
            type: 'technicalService',
            newStatusStage: statusStage,
            maxStatusStage: conditionOfStatusStage.statusList.length,
            succeed: succeed,
          });
        }
      }
      // if (type == 1 || type == 3) {
      //   this.$store.dispatch("receipt/postNewProgress", {
      //     deviceId: this.device.id,
      //     name: serviceStatusTech.name,
      //     category: serviceStatusTech.category,
      //     type: "sparePart",
      //     newStatusStage: serviceStatusTech.statusStage + 1,
      //     maxStatusStage: serviceStatusTech.statusList.length,
      //   });
      // }
      // if (type == 2 || type == 3) {
      //   this.$store.dispatch("receipt/postNewProgress", {
      //     deviceId: this.device.id,
      //     name: serviceStatusTech.name,
      //     category: serviceStatusTech.category,
      //     type: "technicalService",
      //     newStatusStage: serviceStatusTech.statusStage + 1,
      //     maxStatusStage: serviceStatusTech.statusList.length,
      //   });
      // }
    },
    addInfo(receipt) {
      this.receipt = { ...receipt };
      this.receiptAddInfoDialog = true;
    },
    editReceipt(receipt) {
      this.receipt = { ...receipt };
      this.receiptDialog = true;
    },
    editDevice(device) {
      this.device = { ...device };
      this.deviceDialog = true;
    },
    confirmDeleteReceipt(receipt) {
      this.receipt = receipt;
      this.deleteReceiptDialog = true;
    },
    confirmDeleteDevice(device) {
      this.device = device;
      this.deleteDeviceDialog = true;
    },
    deleteService(service) {
      this.receipt.totalPrice -= service.price;
      this.technicalServiceDetails = this.technicalServiceDetails.filter(
        (val) => val.id !== service.id,
      );
      this.device.technicalServiceDetails =
        this.device.technicalServiceDetails.filter(
          (val) => val.id !== service.id,
        );
      this.service = {};
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Service Deleted',
        life: 3000,
      });
    },
    deleteSparePart(sparePart) {
      this.receipt.totalPrice -= sparePart.price;
      this.sparePartDetails = this.sparePartDetails.filter(
        (val) => val.id !== sparePart.id,
      );
      this.device.sparePartDetails = this.device.sparePartDetails.filter(
        (val) => val.id !== sparePart.id,
      );
      this.sparePart = {};
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Spare-Part Deleted',
        life: 3000,
      });
    },
    deleteReceipt() {
      this.receipts = this.receipts.filter((val) => val.id !== this.receipt.id);
      this.deleteReceiptDialog = false;
      this.receipt = {};
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Receipt Deleted',
        life: 3000,
      });
    },
    deleteDevice() {
      this.receipt.deviceDetails = this.receipt.deviceDetails.filter(
        (val) => val.id !== this.device.id,
      );
      this.receipt.totalPrice = 0;
      this.deleteDeviceDialog = false;
      this.device = {};
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Device Deleted',
        life: 3000,
      });
    },
    findIndexByCodeDevice(code) {
      let index = -1;
      for (let i = 0; i < this.receipt.deviceDetails.length; i++) {
        if (this.receipt.deviceDetails[i].code === code) {
          index = i;
          break;
        }
      }

      return index;
    },
    findIndexByIdReceipt(id) {
      let index = -1;
      for (let i = 0; i < this.receipts.length; i++) {
        if (this.receipts[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let id = '';
      var chars1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      var chars2 = '0123456789';
      for (var i = 0; i < 3; i++) {
        id += chars1.charAt(Math.floor(Math.random() * chars1.length));
      }
      for (var j = 0; j < 3; j++) {
        id += chars2.charAt(Math.floor(Math.random() * chars2.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    // getBase64Image(img) {
    //   var canvas = document.createElement("canvas");
    //   canvas.width = img.width;
    //   canvas.height = img.height;
    //   var ctx = canvas.getContext("2d");
    //   ctx.drawImage(img, 0, 0);
    //   var dataURL = canvas.toDataURL("image/png");
    //   return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    // },
    exportReceipt(receipt) {
      this.loadingPrint = true;
      this.receipt = { ...receipt };
      var img = document.getElementById('img');
      img.crossOrigin = 'Anonymous';
      img.onload = this.getDataUrl(img);

      for (var i = 0; i < this.receipt.deviceDetails.length; i++) {
        const doc = new jsPDF();
        doc.addImage(img, 'JPEG', 6, 4, 200, 120);
        doc.addImage(img, 'JPEG', 6, 129, 200, 120);
        doc.setFontSize(11);

        var img2 = document.getElementById(
          'img' + this.receipt.deviceDetails[i].id,
        );

        img2.crossOrigin = 'Anonymous';
        img2.onload = this.getDataUrl(img2);
        doc.addImage(img2, 'JPEG', 65, 250, 75, 40, 'alias', 'MEDIUM');

        for (var q = 0; q < 2; q++) {
          var d = new Date(this.receipt.initialDate);
          var additionalY = q * 125;
          doc.text(
            'Receipt Code: \n' + this.receipt.receiptCode,
            172,
            10 + additionalY,
          );
          doc.text(this.receipt.customerName, 25, 33 + additionalY);
          doc.text(this.receipt.customerContact, 100, 33 + additionalY);
          doc.text(this.receipt.receiverName, 130, 31 + additionalY);

          // doc.text(d.toString(), 168, 33 + additionalY);
          doc.text(this.dateCustom(d), 165, 31 + additionalY);

          var x = 38;
          var y = 40 + additionalY;
          var count = 0;

          for (
            var j = 0;
            j < this.receipt.deviceDetails[i].technicalServiceDetails.length;
            j++
          ) {
            count++;
            doc.text(
              this.receipt.deviceDetails[i].technicalServiceDetails[
                j
              ].price.toLocaleString(),
              x,
              y,
            );
            if (
              j + 1 !=
                this.receipt.deviceDetails[i].technicalServiceDetails.length ||
              this.receipt.deviceDetails[i].sparePartDetails.length
            ) {
              doc.text(
                '+',
                x +
                  this.receipt.deviceDetails[i].technicalServiceDetails[
                    j
                  ].price.toLocaleString().length *
                    2,
                y,
              );
            }
            x =
              x +
              this.receipt.deviceDetails[i].technicalServiceDetails[
                j
              ].price.toLocaleString().length *
                2 +
              2;
          }
          for (
            var l = 0;
            l < this.receipt.deviceDetails[i].sparePartDetails.length;
            l++
          ) {
            count++;
            doc.text(
              this.receipt.deviceDetails[i].sparePartDetails[
                l
              ].price.toLocaleString(),
              x,
              y,
            );
            if (
              l + 1 !=
              this.receipt.deviceDetails[i].sparePartDetails.length
            ) {
              doc.text(
                '+',
                x +
                  this.receipt.deviceDetails[i].sparePartDetails[
                    l
                  ].price.toLocaleString().length *
                    2,
                y,
              );
            }
            x =
              x +
              this.receipt.deviceDetails[i].sparePartDetails[
                l
              ].price.toLocaleString().length *
                2 +
              2;
          }

          var x2 = 101;
          var y2 = 40 + additionalY;
          if (count > 1) {
            doc.text('=' + this.receipt.totalPrice.toLocaleString(), x, y);
          }
          doc.text(
            '(' + this.receipt.deviceDetails[i].color.name + ')',
            x2,
            y2,
          );
          x2 += this.receipt.deviceDetails[i].color.name.length * 2 + 4;
          doc.text(this.receipt.deviceDetails[i].brand.name, x2, y2);
          x2 += this.receipt.deviceDetails[i].brand.name.length * 2 + 2;
          doc.text(this.receipt.deviceDetails[i].model, x2, y2);

          var x3 = 25;
          var y3 = 47 + additionalY;

          for (
            var k = 0;
            k < this.receipt.deviceDetails[i].defects.length;
            k++
          ) {
            if (k % 2 == 0 && k != 0) {
              x3 = 25;
              y3 += 5;
            }
            doc.text(this.receipt.deviceDetails[i].defects[k].name, x3, y3);
            if (k + 1 != this.receipt.deviceDetails[i].defects.length) {
              doc.text(
                '+',
                x3 + this.receipt.deviceDetails[i].defects[k].name.length * 2,
                y3,
              );
            }
            x3 += this.receipt.deviceDetails[i].defects[k].name.length * 2 + 4;
          }

          var x4 = 146;
          var y4 = 47 + additionalY;

          for (
            var m = 0;
            m < this.receipt.deviceDetails[i].leftWiths.length;
            m++
          ) {
            doc.text(this.receipt.deviceDetails[i].leftWiths[m], x4, y4);
            if (m + 1 != this.receipt.deviceDetails[i].leftWiths.length) {
              doc.text(
                '+',
                x4 + this.receipt.deviceDetails[i].leftWiths[m].length * 2.4,
                y4,
              );
            }
            x4 += this.receipt.deviceDetails[i].leftWiths[m].length * 2.6 + 4;
          }

          var x5 = 35;
          var y5 = 88 + additionalY;

          doc.text('Service Used:', 10, y5);

          if (
            this.receipt.deviceDetails[i].technicalServiceDetails.length > 0
          ) {
            for (
              var n = 0;
              n < this.receipt.deviceDetails[i].technicalServiceDetails.length;
              n++
            ) {
              doc.text(
                this.receipt.deviceDetails[i].technicalServiceDetails[n].name,
                x5,
                y5,
              );
              if (
                n + 1 !=
                this.receipt.deviceDetails[i].technicalServiceDetails.length
              ) {
                doc.text(
                  '+',
                  x5 +
                    this.receipt.deviceDetails[i].technicalServiceDetails[n]
                      .name.length *
                      2.2,
                  y5,
                );
              }
              x5 +=
                this.receipt.deviceDetails[i].technicalServiceDetails[n].name
                  .length *
                  2.2 +
                4;
            }
          } else {
            doc.text(' - ', x5, y5);
          }

          var x6 = 50;
          var y6 = 93 + additionalY;

          doc.text('Spare Part Purchased:', 10, y6);
          if (this.receipt.deviceDetails[i].sparePartDetails.length > 0) {
            for (
              var p = 0;
              p < this.receipt.deviceDetails[i].sparePartDetails.length;
              p++
            ) {
              doc.text(
                this.receipt.deviceDetails[i].sparePartDetails[p].name,
                x6,
                y6,
              );
              if (
                p + 1 !=
                this.receipt.deviceDetails[i].sparePartDetails.length
              ) {
                doc.text(
                  '+',
                  x6 +
                    this.receipt.deviceDetails[i].sparePartDetails[p].name
                      .length *
                      2.2,
                  y6,
                );
              }
              x6 +=
                this.receipt.deviceDetails[i].sparePartDetails[p].name.length *
                  2.2 +
                4;
            }
          } else {
            doc.text(' - ', x6, y6);
          }

          doc.setLineDash([7, 3, 1, 3], 10);
          doc.line(0, 127, 275, 127);
        }
        doc.save(
          'receipt_R' +
            this.receipt.id +
            'D' +
            this.receipt.deviceDetails[i].id +
            '_.pdf',
        );
      }
      this.loadingPrint = false;
    },
    getDataUrl(img) {
      // Create canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      // Set width and height
      canvas.width = img.width;
      canvas.height = img.height;
      // Draw the image
      ctx.drawImage(img, 0, 0);
      return canvas.toDataURL('image/jpeg');
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.receipt-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

::v-deep(.row-devices) {
  background-color: rgba(77, 255, 92, 0.219) !important;
}

.p-dialog .receipt-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.device-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .device-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
.multiselect-custom {
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .country-item {
    display: flex;
    align-items: center;

    span.flag {
      width: 18px;
      height: 12px;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  .country-item-value {
    border-radius: 3px;
    // display: inline-flex;
    // display: list-item;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    border-style: solid;
    // border-color: coral;
    border-color: #c63737;
    // border-blokcolor: #f75b5b;
    // background-color: #f75b5b;
    // color: #ffffff;
    color: black;
  }
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-responsive-demo {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: center;
        }

        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}

.inProgress {
  color: red;
}
.done {
  color: green;
}
.p-invalid {
  color: red;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// ::v-deep(.p-column-title) {
//   &.--inProgress {
//     .p-column-title {
//       color: red;
//     }
//   }
//   &.--done {
//     .p-column-title {
//       color: green;
//     }
//   }
// }
</style>
